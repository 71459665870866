import styled from 'styled-components/macro';

import { palette, PaletteProps } from '../../../styles/tools';

export const Tag = styled.span<PaletteProps>`
  color: ${palette('color', 'default', 0)};
  position: relative;
  background: color-mix(
    in oklab,
    ${palette('background', 'default', 0)} 15%,
    transparent
  );
  padding: 0.125rem 0.5rem;
  border-radius: 100px;
  font-size: 13px;
  line-height: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.8px;
`;
