import styled from 'styled-components/macro';
import { ifProp } from '../../../styles/tools';

export interface SvgIconProps {
  inline?: boolean;
}

export const SVGIcon = styled.svg<SvgIconProps>`
  display: ${ifProp('inline', 'inline', 'block')};
`;
