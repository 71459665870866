import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AuthContext } from '../../context/AuthContext';
import {
  UserValidateProps,
  validateRoutePermissions,
} from '../../utils/validate-user-permissions';
import { PageWrapper } from '../components/PageWrapper';
import { useBillingContext } from '../../context/BillingContext';
import { CoachPlusActivation as CoachPlusActivationOrigin } from '../features/CoachPlusActivation';

type Props = RouteProps & UserValidateProps;

export const PrivateRoute = ({
  permissions,
  tags,
  subscription,
  ...rest
}: Props) => {
  const { isAuthenticated, user, loadingUserData } = useContext(AuthContext);
  const { hasAllPermissions, hasAllTags } = validateRoutePermissions({
    user,
    permissions,
    tags,
  });
  const hasAccess = hasAllPermissions && hasAllTags;
  const { hasSubscription, isLoading } = useBillingContext();

  if (loadingUserData || isLoading) {
    return <PageWrapper loading />;
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: '/auth/login', state: { from: rest.location } }}
      />
    );
  }

  if (subscription && !hasSubscription) {
    return <CoachPlusActivation />;
  }

  if (!hasAccess) {
    return <Redirect to={{ pathname: '/', state: { from: rest.location } }} />;
  }

  return <Route {...rest} />;
};

const CoachPlusActivation = styled(CoachPlusActivationOrigin)`
  height: 100vh;
`;
