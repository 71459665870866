import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

import { Col, Row } from '../../Grid';
import { NavButton, NavLink } from './NavLink';
import { Icon } from '../../Icon';
import { AuthContext } from '../../../../context/AuthContext';
import { Label } from '../../Label';

import { palette } from '../../../../styles/tools';
import { media } from '../../../../styles/media';
import {
  BarChartOutlinedIcon,
  CoachPlusIcon,
  CupIcon,
  LineChartIcon,
  MailIcon,
  QuestionnaireIcon,
} from '../../IconV2';
import { Tag } from '../../Tag';
import { useBillingContext } from '../../../../context/BillingContext';

export function PlusDesktopNav() {
  const { t } = useTranslation();
  const { signOut } = useContext(AuthContext);
  const { hasSubscription } = useBillingContext();

  return (
    <Wrapper>
      <Row gutter={0}>
        <Col xs={12} gutter={2}>
          <RouterLink to="/">
            <Row alignItems="center" gutter={0.5}>
              <Col gutter={0.5}>
                <CoachPlusIcon />
              </Col>
              <Col gutter={0.5}>
                <NavLogo>Coach+</NavLogo>
              </Col>
            </Row>
          </RouterLink>
        </Col>
        <Col xs={12} gutter={0} gutterY={1.25}>
          <Row gutter={0} gutterY={0.25}>
            <Col xs={12} gutterY={0.25} gutter={0}>
              <NavLink to="/plus/home">
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="home" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.home')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutterY={0.25} gutter={0}>
              <NavLink to="/plus/my-profile">
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="person" />
                  </Col>
                  <Col gutter={0.5}>
                    {t('navigation.myProfile')}&nbsp;&nbsp;
                    {!hasSubscription && (
                      <Tag palette="orange">{t('label.free')}</Tag>
                    )}
                  </Col>
                </Row>
              </NavLink>
            </Col>
          </Row>
        </Col>
        <Col xs={12} gutter={0} gutterY={1.5}>
          <Row gutter={0} gutterY={0.25}>
            <Col xs={12} gutter={2}>
              <Label>{t('label.editMyProfile')}</Label>
            </Col>
            <Col xs={12} gutterY={0.25} gutter={0}>
              <NavLink to="/plus/files">
                <Row alignItems="center" gutter={0.5} flexWrap="nowrap">
                  <Col gutter={0.5}>
                    <Icon name="file" block />
                  </Col>
                  <Col gutter={0.5}>
                    {t('navigation.cvAndFiles')}&nbsp;&nbsp;
                    {!hasSubscription && (
                      <Tag palette="orange">{t('label.free')}</Tag>
                    )}
                  </Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutterY={0.25} gutter={0}>
              <NavLink to="/plus/player-impact">
                <Row alignItems="center" gutter={0.5} flexWrap="nowrap">
                  <Col gutter={0.5}>
                    <BarChartOutlinedIcon />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.playerImpact')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutterY={0.25} gutter={0}>
              <NavLink to="/plus/questionnaire">
                <Row alignItems="center" gutter={0.5} flexWrap="nowrap">
                  <Col gutter={0.5}>
                    <QuestionnaireIcon />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.questionnaire')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutterY={0.25} gutter={0}>
              <NavLink to="/plus/contact">
                <Row alignItems="center" gutter={0.5} flexWrap="nowrap">
                  <Col gutter={0.5}>
                    <MailIcon />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.myContact')}</Col>
                </Row>
              </NavLink>
            </Col>
          </Row>
        </Col>
        <Col xs={12} gutter={0} gutterY={1.5}>
          <Row gutter={0} gutterY={0.25}>
            <Col xs={12} gutter={2}>
              <Label>{t('label.tools')}</Label>
            </Col>
            <Col xs={12} gutter={0}>
              <NavLink to="/plus/compare">
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="compare" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.compare')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutter={0}>
              <NavLink to="/plus/development">
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <LineChartIcon />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.development')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutter={0}>
              <NavLink to="/plus/my-league">
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <CupIcon />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.myLeague')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutter={0}>
              <NavLink to="/news" permissions={['access_news']}>
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="news" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.spotlight')}</Col>
                </Row>
              </NavLink>
            </Col>
          </Row>
        </Col>
        <Col xs={12} gutter={0} gutterY={1.5}>
          <Row gutter={0} gutterY={0.25}>
            <Col xs={12} gutter={2}>
              <Label>{t('label.user')}</Label>
            </Col>
            <Col xs={12} gutter={0}>
              <NavLink to="/plus/access-requests">
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="key" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.accessRequests')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutter={0}>
              <NavLink to="/settings">
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="settings" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.settings')}</Col>
                </Row>
              </NavLink>
            </Col>
            <Col xs={12} gutter={0}>
              <NavButton onClick={signOut}>
                <Row alignItems="center" gutter={0.5}>
                  <Col gutter={0.5}>
                    <Icon name="log-out" />
                  </Col>
                  <Col gutter={0.5}>{t('navigation.logOut')}</Col>
                </Row>
              </NavButton>
            </Col>
          </Row>
        </Col>
      </Row>
      {/*<div>You have [X] days left in your free trial</div>*/}
    </Wrapper>
  );
}

const Wrapper = styled.header`
  padding: 1.5rem 0.5rem;
  background: ${palette('card', 'default')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 14rem;
  backdrop-filter: none;

  ${media.xl} {
    padding: 2rem 0.5rem;
    width: 15rem;
  }

  @media print {
    display: none;
  }
`;

const NavLogo = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.8px;
  font-weight: 700;
`;
