import { PermissionType, Role, RoleTagType } from '../types/Dict';

export interface UserValidateProps {
  permissions?: PermissionType[];
  tags?: (RoleTagType | `!${RoleTagType}`)[];
  subscription?: boolean;
}

interface User {
  id: number;
  email: string;
  role: Role;
}

interface validateUserPermissionsParams extends UserValidateProps {
  user: User;
}

export function validateUserTags(
  user: User,
  tags?: (RoleTagType | `!${RoleTagType}`)[],
): boolean {
  if (!tags?.length) return true;

  const allowedTags: RoleTagType[] =
    tags?.filter((tag): tag is RoleTagType => !tag.startsWith('!')) || [];

  const disallowedTags: RoleTagType[] =
    tags
      ?.filter(tag => tag.startsWith('!'))
      ?.map(tag => tag.replace('!', '') as RoleTagType) || [];

  const allowed = allowedTags?.length
    ? allowedTags.includes(user?.role?.tag)
    : true;
  const disallowed = disallowedTags?.length
    ? disallowedTags.includes(user?.role?.tag)
    : false;

  return allowed && !disallowed;
}

export function validateUserPermissions(
  user: User,
  permissions?: PermissionType[],
): boolean {
  if (!permissions?.length) return true;

  return permissions.every(permission => {
    return (user?.role.permissions || {})[permission];
  });
}

export function validateRoutePermissions({
  user,
  permissions,
  tags,
}: validateUserPermissionsParams) {
  const hasAllPermissions = validateUserPermissions(user, permissions);
  const hasAllTags = validateUserTags(user, tags);

  return { hasAllPermissions, hasAllTags };
}
