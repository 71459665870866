import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { trainerTickerSaga } from './saga';
import { GetAction, TrainerTickerState } from './types';
import { TrainerTicker } from '../../../../types/TrainerTicker';

export const initialState: TrainerTickerState = {
  tickers: [],
  total: 0,
  getLoading: false,
  days: 7,
};

const slice = createSlice({
  name: 'trainerTicker',
  initialState,
  reducers: {
    getTickers(state, actions: PayloadAction<GetAction>) {
      state.getLoading = true;
    },
    getTickersSuccess(
      state,
      action: PayloadAction<{
        tickers: TrainerTicker[];
        total: number;
        days: 7 | 30;
      }>,
    ) {
      const { tickers, total, days } = action.payload;
      state.tickers = tickers;
      state.total = total;
      state.getLoading = false;
      state.days = days;
    },
    getTickersError(state) {
      state.getLoading = false;
    },
  },
});

export const { actions: trainerTickerActions } = slice;

export const useTrainerTickerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: trainerTickerSaga });
  return { actions: slice.actions };
};
