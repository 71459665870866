import React from 'react';

import { SVGIcon, SvgIconProps } from './SVGIcon';

interface Props extends SvgIconProps {
  width?: number;
  height?: number;
}

export function LMAIcon(props: Props) {
  return (
    <SVGIcon
      viewBox="0 0 409.03 158.84"
      style={{ filter: 'drop-shadow(0px 0px 2px rgb(0 0 0 / 0.4))' }}
      {...props}
    >
      <polygon
        fill="currentColor"
        points="0 1.36 0 85.42 54.54 85.42 54.54 70.78 16.41 70.78 16.41 1.36 0 1.36"
      />
      <polygon
        fill="currentColor"
        points="122.7 85.42 122.7 36.42 106.64 68.3 95.55 68.3 79.37 36.42 79.37 85.42 62.96 85.42 62.96 1.36 79.14 1.36 101.09 46.81 122.93 1.36 139.11 1.36 139.11 85.42 122.7 85.42"
      />
      <path
        fill="currentColor"
        d="m201.6,85.27l-4.96-14.88h-29.87l-5.08,14.88h-17.12L175.15,1.22h12.87l30.69,84.06h-17.12Zm-19.6-59.26l-10.63,30.58h20.9l-10.27-30.58Z"
      />
      <polygon
        fill="currentColor"
        points="1.84 137.81 1.84 121.66 4.7 121.66 4.7 135.24 12.71 135.24 12.71 137.81 1.84 137.81"
      />
      <polygon
        fill="currentColor"
        points="14.47 137.81 14.47 121.66 25.58 121.66 25.58 124.2 17.28 124.2 17.28 128.29 25.34 128.29 25.34 130.81 17.28 130.81 17.28 135.24 25.58 135.24 25.58 137.81 14.47 137.81"
      />
      <path
        fill="currentColor"
        d="m34.64,124.83l-2.27,6.39h4.53l-2.25-6.39Zm4.62,12.97l-1.5-4.11h-6.29l-1.48,4.11h-2.98l6.24-16.14h2.93l6.2,16.14h-3.12Z"
      />
      <path
        fill="currentColor"
        d="m54.44,137.81v-1.21c-1.21,1.16-2.88,1.4-4.6,1.4-6.24,0-6.24-3.85-6.24-8.28s.07-8.23,6.83-8.23c3.58,0,6.24.99,6.24,4.7h-2.83c0-1.6-.87-2.11-3.63-2.11-3.24,0-3.75,1.04-3.75,5.66s.48,5.62,3.63,5.64c3.73.02,3.82-1.65,3.85-4.65h-3.97v-2.47h6.73v9.54h-2.25Z"
      />
      <path
        fill="currentColor"
        d="m72.25,133.09c-.19,3.49-2.4,4.91-6.1,4.91s-5.9-1.43-6.1-4.91c-.05-.75-.05-1.77-.05-3.39v-8.04h2.86v8.04c0,4.19-.22,5.69,3.29,5.69s3.29-1.5,3.29-5.69v-8.04h2.86v8.04c0,1.62,0,2.64-.05,3.39"
      />
      <polygon
        fill="currentColor"
        points="75.64 137.81 75.64 121.66 86.75 121.66 86.75 124.2 78.45 124.2 78.45 128.29 86.51 128.29 86.51 130.81 78.45 130.81 78.45 135.24 86.75 135.24 86.75 137.81 75.64 137.81"
      />
      <polygon
        fill="currentColor"
        points="108.66 137.81 108.66 125.27 104.52 137.81 101.76 137.81 97.67 125.27 97.67 137.81 95.05 137.81 95.05 121.66 98.83 121.66 103.21 134.51 107.57 121.64 111.32 121.64 111.32 137.81 108.66 137.81"
      />
      <path
        fill="currentColor"
        d="m120.76,124.83l-2.27,6.39h4.53l-2.25-6.39Zm4.62,12.97l-1.5-4.11h-6.29l-1.48,4.11h-2.98l6.24-16.14h2.93l6.2,16.14h-3.12Z"
      />
      <polygon
        fill="currentColor"
        points="139.69 137.81 132.98 126.21 132.98 137.81 130.32 137.81 130.32 121.66 133.23 121.66 139.96 133.16 139.96 121.66 142.62 121.66 142.62 137.81 139.69 137.81"
      />
      <path
        fill="currentColor"
        d="m152.06,124.83l-2.27,6.39h4.53l-2.25-6.39Zm4.62,12.97l-1.5-4.11h-6.29l-1.48,4.11h-2.98l6.24-16.14h2.93l6.2,16.14h-3.12Z"
      />
      <path
        fill="currentColor"
        d="m171.86,137.81v-1.21c-1.21,1.16-2.88,1.4-4.6,1.4-6.24,0-6.24-3.85-6.24-8.28s.07-8.23,6.83-8.23c3.58,0,6.25.99,6.25,4.7h-2.83c0-1.6-.87-2.11-3.63-2.11-3.24,0-3.75,1.04-3.75,5.66s.48,5.62,3.63,5.64c3.73.02,3.82-1.65,3.85-4.65h-3.97v-2.47h6.73v9.54h-2.25Z"
      />
      <polygon
        fill="currentColor"
        points="177.52 137.81 177.52 121.66 188.63 121.66 188.63 124.2 180.33 124.2 180.33 128.29 188.39 128.29 188.39 130.81 180.33 130.81 180.33 135.24 188.63 135.24 188.63 137.81 177.52 137.81"
      />
      <path
        fill="currentColor"
        d="m200.74,124.71c-.39-.36-.53-.51-3.41-.51h-2.76v4.48h2.93c.75,0,2.42.02,2.9-.19.36-.17.82-.63.82-2.06,0-.94-.22-1.48-.48-1.72m.51,13.09v-4.19c0-2.45,0-2.37-3.95-2.37h-2.74v6.56h-2.86v-16.14h5.66c3.65,0,6.66.15,6.66,4.65,0,1.94-.39,3.1-1.57,3.58,1.79.53,1.57,2.64,1.57,3.97v3.95h-2.78Z"
      />
      <path
        fill="currentColor"
        d="m213.4,138c-3.9,0-6.49-.97-6.49-4.7h2.86c0,1.62.77,2.13,3.68,2.13,3.12,0,3.63-.77,3.63-2.54,0-1.57-.61-1.79-1.81-1.89-3.56-.27-8.35.73-8.35-4.6,0-3.75,2.18-4.91,6.44-4.91,3.9,0,6.41.99,6.41,4.7h-2.81c0-1.91-1.21-2.13-3.65-2.13-3.22,0-3.53.61-3.53,2.3,0,1.5.68,1.69,1.82,1.79,3.56.29,8.37-.75,8.35,4.62-.02,3.63-2.15,5.23-6.53,5.23"
      />
      <path
        fill="currentColor"
        d="m7.82,145.67l2.25,6.39h-4.53l2.27-6.39Zm-1.38-3.17L.19,158.65h2.98l1.48-4.12h6.29l1.5,4.12h3.12l-6.2-16.14h-2.93Z"
      />
      <path
        fill="currentColor"
        d="m22.92,158.84c-3.9,0-6.49-.97-6.49-4.7h2.86c0,1.62.77,2.13,3.68,2.13,3.12,0,3.63-.77,3.63-2.54,0-1.57-.61-1.79-1.82-1.89-3.56-.27-8.35.73-8.35-4.6,0-3.75,2.18-4.91,6.44-4.91,3.9,0,6.41.99,6.41,4.7h-2.81c0-1.91-1.21-2.13-3.65-2.13-3.22,0-3.53.61-3.53,2.3,0,1.5.68,1.69,1.82,1.79,3.56.29,8.37-.75,8.35,4.62-.02,3.63-2.15,5.23-6.53,5.23"
      />
      <path
        fill="currentColor"
        d="m38.44,158.84c-3.9,0-6.49-.97-6.49-4.7h2.86c0,1.62.77,2.13,3.68,2.13,3.12,0,3.63-.77,3.63-2.54,0-1.57-.61-1.79-1.82-1.89-3.56-.27-8.35.73-8.35-4.6,0-3.75,2.18-4.91,6.44-4.91,3.9,0,6.41.99,6.41,4.7h-2.81c0-1.91-1.21-2.13-3.65-2.13-3.22,0-3.53.61-3.53,2.3,0,1.5.68,1.69,1.82,1.79,3.56.29,8.37-.75,8.35,4.62-.02,3.63-2.15,5.23-6.54,5.23"
      />
      <path
        fill="currentColor"
        d="m54.29,144.92c-3.29,0-3.78,1.02-3.78,5.66s.48,5.64,3.78,5.64,3.8-1.02,3.8-5.66-.51-5.64-3.8-5.64m.02,13.92c-6.58,0-6.66-3.56-6.66-8.28s.05-8.23,6.63-8.23,6.66,3.46,6.66,8.21c0,5.11-.31,8.3-6.63,8.3"
      />
      <path
        fill="currentColor"
        d="m73.9,147.03c0-1.62-.68-2.11-3.46-2.11-3.24,0-3.75,1.04-3.75,5.66s.48,5.64,3.73,5.64c2.78,0,3.46-.46,3.46-2.08h2.81c0,3.65-2.37,4.7-6.05,4.7-6.75,0-6.8-3.65-6.8-8.28s.07-8.23,6.83-8.23c3.68,0,6.05,1.04,6.05,4.7h-2.81Z"
      />
      <rect
        fill="currentColor"
        x="79.78"
        y="142.5"
        width="2.86"
        height="16.14"
      />
      <path
        fill="currentColor"
        d="m92.08,145.67l2.25,6.39h-4.53l2.27-6.39Zm-1.38-3.17l-6.24,16.14h2.98l1.48-4.12h6.29l1.5,4.12h3.12l-6.2-16.14h-2.93Z"
      />
      <polygon
        fill="currentColor"
        points="98.49 142.5 98.49 145.04 103.41 145.04 103.41 158.65 106.26 158.65 106.26 145.04 111.18 145.04 111.18 142.5 98.49 142.5"
      />
      <rect
        fill="currentColor"
        x="113.21"
        y="142.5"
        width="2.86"
        height="16.14"
      />
      <path
        fill="currentColor"
        d="m125.87,144.92c-3.29,0-3.78,1.02-3.78,5.66s.48,5.64,3.78,5.64,3.8-1.02,3.8-5.66-.51-5.64-3.8-5.64m.02,13.92c-6.58,0-6.66-3.56-6.66-8.28s.05-8.23,6.63-8.23,6.66,3.46,6.66,8.21c0,5.11-.31,8.3-6.63,8.3"
      />
      <polygon
        fill="currentColor"
        points="145.33 142.5 145.33 154 138.6 142.5 135.7 142.5 135.7 158.65 138.36 158.65 138.36 147.05 145.06 158.65 147.99 158.65 147.99 142.5 145.33 142.5"
      />
      <rect fill="#009b3e" x="0" y="95.26" width="220.47" height="16.52" />
      <path
        fill="currentColor"
        d="m393.91,28.35s.33,3.02-4.67,11.31c0,0,4.02,3.82,7.63,13.88,0,0-19.66-7.57-50.11-9.6,0,0-6.87-28.59-16.18-40.55,0,0,6.02.4,14.33,2.67,0,0,8.5-6.95,11.26-5.97,0,0,22.32,7.11,37.76,28.26"
      />
      <path
        fill="currentColor"
        d="m400.06,91.06c.68-7.62.32-13.78.32-13.78-16.14,17.28-43.5,18.69-43.5,18.69,18.31,19.98,16.01,43.99,16.01,43.99,4.67-3.57,8.11-7.83,8.11-7.83,7.42-.16,11.14-4.26,11.14-4.26,0,0,13.55-16.21,16.88-38.71.02-.08.02-.14.02-.14-.13.44-.36.79-.61,1.13-.88.88-3.05,1.81-8.37.91"
      />
      <path
        fill="currentColor"
        d="m294.76,2.39s.08-.05.08-.05c-18.44,7.64-30.71,23.81-30.71,23.81-4.66,5.62-.99,10.62-.99,10.62-5.55,6.44-7.21,10.62-7.21,10.62,20.09-9.17,37.67-2.49,37.67-2.49-.93-20.04,13.77-39.44,13.77-39.44-5.67,1.07-10.45,2.92-12.34,3.7-2.93-4.54-.71-6.46-.27-6.77"
      />
      <path
        fill="currentColor"
        d="m347.77,156.13c-35.67-8.75-38.67-27.95-38.67-27.95,0,0-5.79,8.91-32.68,12.45,0,0,32.17,26.11,71.35,15.5"
      />
      <path
        fill="currentColor"
        d="m264.16,127.91s-2.98-21.35,7.1-36.39c0,0-13.67-7.69-23.84-27.49,0,0-7.16,33.92,16.75,63.88"
      />
      <polygon
        fill="currentColor"
        points="281.64 89.15 297.84 53.92 335.73 58.22 343.6 96.32 310.24 115.2 281.64 89.15"
      />
    </SVGIcon>
  );
}
