import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
  useMemo,
} from 'react';
import { AxiosResponse } from 'axios';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { api } from '../../services/api';
import { useAuth } from '../AuthContext';
import { usePermissions } from '../../hooks/usePermissions';

interface Product {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: null | number;
  livemode: boolean;
  lookup_key: null | string;
  metadata: Record<string, any>;
  nickname: null | string;
  product: string;
  recurring: {
    aggregate_usage: null | string;
    interval: 'day' | 'week' | 'month' | 'year';
    interval_count: number;
    meter: null | string;
    trial_period_days: null | number;
    usage_type?: string;
  };
  tax_behavior: string;
  tiers_mode: null | string;
  transform_quantity: null | any;
  type: 'one_time' | 'recurring';
  unit_amount: number;
  unit_amount_decimal: string;
}

interface Customer {
  id: number;
  user_id: number;
  stripe_subscription_id: string;
  stripe_customer_id: string;
  status:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'paused';
  stripe_price_id: string;
  start_date: string;
  end_date: string;
  trial_end_date: string;
  created_at: string;
  updated_at: string;
}

interface BillingContextType {
  stripe: Stripe | null;
  isLoading: boolean;
  createCheckoutSession: () => Promise<void>;
  createCustomerPortalSession: () => Promise<void>;
  product: Product | null;
  customer: Customer | null;
  hasSubscription: boolean;
}

const BillingContext = createContext<BillingContextType | undefined>(undefined);

export const BillingProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [stripe, setStripe] = useState<BillingContextType['stripe']>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState<BillingContextType['product']>(null);
  const { isAuthenticated, loadingUserData } = useAuth();
  const [customer, setCustomer] =
    useState<BillingContextType['customer']>(null);
  const isCoach = usePermissions({ tags: ['coach'] });

  const initialize = async () => {
    if (!isCoach) {
      setIsLoading(false);
      return;
    }

    try {
      const [stripeInstance, productResponse, subscriptionResponse] =
        await Promise.all([
          loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!),
          api.get('/billing/default-price'),
          api.get('/billing'),
        ]);

      setStripe(stripeInstance);
      setProduct(productResponse.data);
      setCustomer(subscriptionResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!loadingUserData) {
      if (isAuthenticated) {
        initialize();
      } else {
        setIsLoading(false);
      }
    }
  }, [isAuthenticated, loadingUserData]);

  const createCheckoutSession = async () => {
    if (!stripe) {
      throw new Error('Stripe has not been initialized');
    }

    const response: AxiosResponse<{ id: string }> = await api.get(
      '/billing/checkout-session',
    );

    const result = await stripe.redirectToCheckout({
      sessionId: response.data.id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  const createCustomerPortalSession = async () => {
    const response: AxiosResponse<{ url: string }> = await api.get(
      '/billing/customer-session',
    );

    window.location.href = response.data.url;
  };

  const hasSubscription = useMemo(() => {
    if (isLoading) return true;

    if (
      customer?.trial_end_date &&
      new Date(customer.trial_end_date) > new Date()
    ) {
      return true;
    }

    return (
      !!customer &&
      (customer.status === 'active' || customer.status === 'trialing')
    );
  }, [isLoading, customer?.status]);

  const value = {
    stripe,
    isLoading,
    createCheckoutSession,
    createCustomerPortalSession,
    product,
    customer,
    hasSubscription,
  };

  return (
    <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
  );
};

export const useBillingContext = (): BillingContextType => {
  const context = useContext(BillingContext);
  if (context === undefined) {
    throw new Error('useBillingContext must be used within a BillingProvider');
  }
  return context;
};
