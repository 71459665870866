import React from 'react';

import { SVGIcon } from './SVGIcon';

interface Props {
  width?: number;
  height?: number;
}

export function LineChartIcon(props: Props) {
  return (
    <SVGIcon viewBox="0 0 24 24" fill="none" width={24} height={24} {...props}>
      <path
        d="M22.5 20.4999H2.5C2.23478 20.4999 1.98043 20.3945 1.79289 20.207C1.60536 20.0195 1.5 19.7651 1.5 19.4999V3.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 16.5L10.5 10.5L13.5 13.5L19.5 7.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGIcon>
  );
}
