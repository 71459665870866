import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';

import { ifProp, palette } from '../../../../styles/tools';
import { UserValidateProps } from '../../../../utils/validate-user-permissions';
import { usePermissions } from '../../../../hooks/usePermissions';
import { useBillingContext } from '../../../../context/BillingContext';

type LinkProps = NavLinkProps & UserValidateProps;

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & UserValidateProps;

export const NavLink = ({
  permissions,
  tags,
  subscription,
  ...props
}: LinkProps) => {
  const hasAccess = usePermissions({ permissions, tags });
  const { hasSubscription } = useBillingContext();

  if (!hasAccess) return null;

  return (
    <NavLinkStyled disabled={subscription && !hasSubscription} {...props} />
  );
};

export const NavButton = ({
  permissions,
  tags,
  subscription,
  ...props
}: ButtonProps) => {
  const hasAccess = usePermissions({ permissions, tags });
  const { hasSubscription } = useBillingContext();

  if (!hasAccess) return null;

  return (
    <NavButtonStyled disabled={subscription && !hasSubscription} {...props} />
  );
};

const NavItemStyle = css<{ disabled?: boolean }>`
  padding: 0.25rem 1rem 0.25rem 1.5rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.8px;
  color: ${palette('link', 'default', 0)};
  border: none;
  cursor: pointer;
  display: block;
  ${ifProp(
    'disabled',
    css`
      pointer-events: none;
      opacity: 0.4;
    `,
  )}

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  &:hover {
    color: ${palette('link', 'default', 1)};
  }

  &.active {
    color: ${palette('link', 'default', 2)};
  }
`;

export const NavLinkStyled = styled(RouterNavLink)`
  ${NavItemStyle}
`;

export const NavButtonStyled = styled.button`
  ${NavItemStyle}
`;
