import React, { createContext, useContext, PropsWithChildren } from 'react';
import { UserValidateProps } from '../../../utils/validate-user-permissions';
import { usePermissions } from '../../../hooks/usePermissions';

interface CardContextType {
  linkIsActive: boolean;
}

const CardContext = createContext<CardContextType | undefined>(undefined);

interface CardProviderProps extends UserValidateProps {}

export const CardProvider = (props: PropsWithChildren<CardProviderProps>) => {
  const { children, permissions, tags } = props;
  const hasAccess = usePermissions({ permissions, tags });

  return (
    <CardContext.Provider value={{ linkIsActive: hasAccess }}>
      {children}
    </CardContext.Provider>
  );
};

export const useCardContext = (): CardContextType => {
  const context = useContext(CardContext);
  if (context === undefined) {
    throw new Error('useCardContext must be used within a CardProvider');
  }
  return context;
};
