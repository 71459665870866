import React from 'react';

import { SVGIcon } from './SVGIcon';

interface Props {
  width?: number;
  height?: number;
}

export function MailIcon(props: Props) {
  return (
    <SVGIcon viewBox="0 0 24 24" fill="none" width={24} height={24} {...props}>
      <path
        d="M3.10171 5.07004C2.73043 5.43314 2.5 5.93966 2.5 6.5V17.5C2.5 18.6046 3.39543 19.5 4.5 19.5H19.5C20.6046 19.5 21.5 18.6046 21.5 17.5V6.5C21.5 5.93966 21.2696 5.43314 20.8983 5.07004M3.10171 5.07004C3.46231 4.71738 3.95577 4.5 4.5 4.5H19.5C20.0442 4.5 20.5377 4.71738 20.8983 5.07004M3.10171 5.07004L10.4167 12L10.6245 12.1969C11.3959 12.9277 12.6041 12.9277 13.3755 12.1969L13.5833 12L20.8983 5.07004"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </SVGIcon>
  );
}
