import React from 'react';

import { SVGIcon } from './SVGIcon';

interface Props {
  width?: number;
  height?: number;
}

export function BarChartOutlinedIcon(props: Props) {
  return (
    <SVGIcon viewBox="0 0 24 24" fill="none" width={24} height={24} {...props}>
      <rect
        x="1.5"
        y="14.5"
        width="5"
        height="7"
        rx="1.5"
        stroke="currentColor"
      />
      <rect
        x="9.5"
        y="8.5"
        width="5"
        height="13"
        rx="1.5"
        stroke="currentColor"
      />
      <path
        d="M17.5 4C17.5 3.17157 18.1716 2.5 19 2.5H21C21.8284 2.5 22.5 3.17157 22.5 4V20C22.5 20.8284 21.8284 21.5 21 21.5H19C18.1716 21.5 17.5 20.8284 17.5 20V4Z"
        stroke="currentColor"
      />
    </SVGIcon>
  );
}
