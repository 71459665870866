import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Icon } from '../Icon';
import { Link } from '../Link';
import { palette, PaletteProps, prop, theme } from '../../../styles/tools';
import { UserValidateProps } from '../../../utils/validate-user-permissions';
import { usePermissions } from '../../../hooks/usePermissions';

export interface HeadingProps extends PaletteProps, UserValidateProps {
  level?: number;
  weight?: 400 | 600 | 700 | 900;
}

const fontSize = ({ level }) => {
  switch (level) {
    case 1:
      return 32;
    case 2:
      return 24;
    case 3:
      return 20;
  }

  return 17;
};

const styles = css`
  font-weight: ${prop('weight', 600)};
  font-size: ${fontSize}px;
  line-height: ${({ level }) => fontSize({ level }) + 2}px;
  color: ${palette('heading', 'default')};
  margin: 0;
  overflow-wrap: normal;

  ${Link} & {
    color: inherit;
  }
`;

export const StyledHeading = styled(({ level, children, ...props }) =>
  React.createElement(`h${level}`, props, children),
)`
  ${styles}
`;

const StyledHeadingArrow = styled(StyledHeading)`
  position: relative;
  color: ${theme('global.text')};
  display: inline-block;
  padding-right: 24px;
  font-weight: ${prop('weight', 700)};

  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    top: 50%;
    margin-top: -12px;
    transform: rotate(-90deg);
    stroke-width: 2px;
  }
`;

export const Heading = (props: PropsWithChildren<HeadingProps>) => {
  return <StyledHeading {...props} />;
};

export const HeadingWithArrowV2 = (props: PropsWithChildren<HeadingProps>) => {
  const { children, permissions, tags, ...rest } = props;
  const hasAccess = usePermissions({ permissions, tags });

  return (
    <StyledHeading {...rest}>
      {children}
      {hasAccess && (
        <Tail>
          {'\u00a0'}
          <RotatedIcon name="chevron" level={props.level} />
        </Tail>
      )}
    </StyledHeading>
  );
};

const Tail = styled.span`
  white-space: nowrap;
`;

export const StyledHeadingArrowBack = styled(StyledHeading)`
  position: relative;
  display: inline-block;
  padding-left: ${ifProp({ level: 1 }, '32px', '24px')};
  font-weight: ${prop('weight', 700)};

  svg {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 50%;
    margin-top: -10px;
    transform: rotate(90deg);
  }
`;

const RotatedIcon = styled(Icon)<{ level?: number }>`
  vertical-align: baseline;
  margin: -2px 0;
  position: relative;
  top: ${props => (props?.level && props.level > 2 ? -1 : -2)}px;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(-90deg);
    stroke-width: 2px;
  }
`;

export const HeadingWithArrowBack = (
  props: PropsWithChildren<HeadingProps>,
) => {
  const { children, ...rest } = props;

  return (
    <StyledHeadingArrowBack {...rest}>
      <Icon name="chevron" />
      {children}
    </StyledHeadingArrowBack>
  );
};

Heading.defaultProps = {
  level: 1,
};

StyledHeadingArrow.defaultProps = {
  level: 1,
};

StyledHeadingArrowBack.defaultProps = {
  level: 1,
};
