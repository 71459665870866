import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { Trans, useTranslation } from 'react-i18next';

import { useBillingContext } from '../../../context/BillingContext';
import { theme } from '../../../styles/tools';
import { Col, Row } from '../../components/Grid';
import { Span } from '../../components/Span';
import { Heading } from '../../components/Heading';
import { GetSeasonPassButton } from './GetSeasonPassButton';
import { media } from '../../../styles/media';

interface Props {
  style?: React.CSSProperties;
  className?: string;
}

export function CoachPlusActivation(props: PropsWithChildren<Props>) {
  const { children, style, className } = props;
  const { hasSubscription } = useBillingContext();
  const { t } = useTranslation();

  if (hasSubscription) return <>{children}</>;

  return (
    <CoachPlusActivationWrapper style={style} className={className}>
      {children}
      <CoachPlusActivationBackground>
        <Row flexDirection="column">
          <Col>
            <Heading>
              <Span $color palette="global">
                <Trans
                  i18nKey="articleTitle.activateYourAccount"
                  components={{
                    span: <Span $color palette="orange" />,
                  }}
                  values={{
                    name: 'Coach+',
                  }}
                />
              </Span>
            </Heading>
          </Col>
          <Col gutterY={0.75} style={{ whiteSpace: 'pre-wrap' }}>
            {t('infoMessage.activateYourAccount')}
          </Col>
          <Col gutterY={2}>
            <GetSeasonPassButton />
          </Col>
        </Row>
      </CoachPlusActivationBackground>
    </CoachPlusActivationWrapper>
  );
}

export const CoachPlusActivationBackground = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${theme('palette.loader.default.0')};
  backdrop-filter: blur(0.5rem);
  padding: 2rem;

  ${media.xl} {
    top: -3rem;
    bottom: -3rem;
    left: -3rem;
    right: -3rem;
    backdrop-filter: blur(1.5rem);
    padding: 3rem;
    align-items: center;
  }
`;

export const CoachPlusActivationWrapper = styled.div`
  position: relative;
`;
