import React from 'react';

import { SVGIcon } from './SVGIcon';

interface Props {
  width?: number;
  height?: number;
}

export function CupIcon(props: Props) {
  return (
    <SVGIcon viewBox="0 0 24 24" fill="none" width={24} height={24} {...props}>
      <path
        d="M16.5002 20.5H12.0002M7.5002 20.5H12.0002M12.0002 20.5L12.0002 16.5M12.0002 16.5C18.2891 16.5 17.5002 7.5 17.5002 3.5H6.50018C6.50018 7.5 5.71126 16.5 12.0002 16.5ZM6.47456 5.5H3.0002C2.44791 5.5 1.99519 5.95288 2.03684 6.5036C2.49032 12.5 7.15008 12.5 7.15008 12.5M17.5258 5.5H20.9998C21.5521 5.5 22.0048 5.95288 21.9632 6.5036C21.5097 12.5 16.8503 12.5 16.8503 12.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGIcon>
  );
}
