import React from 'react';

import { SVGIcon } from './SVGIcon';

interface Props {
  width?: number;
  height?: number;
}

export function CoachPlusIcon(props: Props) {
  return (
    <SVGIcon viewBox="0 0 24 24" fill="none" width={24} height={24} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.312 18.2675C13.6183 18.2675 10.624 15.2732 10.624 11.5796C10.624 7.8859 13.6183 4.8916 17.312 4.8916C21.0056 4.8916 23.9999 7.8859 23.9999 11.5796C23.9999 15.2732 21.0056 18.2675 17.312 18.2675ZM15.5602 15.68C15.2623 15.5533 15.1867 15.1837 15.3477 14.9029C15.712 14.2679 15.9707 13.5732 16.11 12.854H13.4537C13.2072 12.854 12.9864 12.6925 12.9386 12.4506C12.8077 11.7872 12.8286 11.0953 13.0003 10.4411C13.0599 10.2139 13.273 10.0673 13.5079 10.0673H16.0603C15.9138 9.43079 15.6747 8.81656 15.3536 8.24814C15.1952 7.96756 15.2728 7.60057 15.5696 7.47515C16.2237 7.19876 16.9414 7.08102 17.6503 7.13367C17.8299 7.14701 17.9861 7.25857 18.0645 7.42076C18.4685 8.25646 18.7505 9.15067 18.8979 10.0673H21.1161C21.3511 10.0673 21.5641 10.2139 21.6221 10.4415C21.7858 11.0845 21.8075 11.797 21.6836 12.4502C21.6376 12.6925 21.4169 12.854 21.1703 12.854H18.9329C18.8006 13.8309 18.494 14.8416 18.056 15.7411C17.9774 15.9025 17.8214 16.0131 17.6423 16.026C16.9326 16.0775 16.2144 15.9581 15.5602 15.68Z"
        fill="#FF9300"
      />
      <path
        d="M0.710597 7.83919C1.18426 6.6872 1.88088 5.64145 2.76068 4.76166C3.64048 3.88186 4.68623 3.18524 5.83821 2.71157C6.9902 2.23791 8.22587 1.99647 9.47466 2.00104C10.7235 2.00562 11.9609 2.25611 13.1164 2.73823C13.5369 2.9137 13.5047 3.50122 13.1032 3.7166C12.5228 4.02791 11.9808 4.40161 11.4863 4.82872C11.3398 4.95524 11.1409 5.00297 10.9523 4.95957C10.4646 4.84736 9.96556 4.78951 9.46446 4.78767C8.58162 4.78444 7.70963 4.95511 6.89793 5.28886C6.08627 5.62259 5.35016 6.11309 4.73114 6.73211C4.11212 7.35114 3.62162 8.08724 3.28788 8.8989C2.95413 9.71061 2.78346 10.5826 2.78669 11.4654C2.78993 12.3483 2.96705 13.2248 3.30902 14.0443C3.651 14.864 4.1509 15.6101 4.7804 16.2396C5.4099 16.8691 6.156 17.369 6.97563 17.711C7.79522 18.0529 8.67164 18.23 9.55454 18.2333C9.99794 18.2349 10.4386 18.1927 10.8703 18.1079C11.0564 18.0714 11.2498 18.1225 11.3916 18.2485C11.9314 18.7281 12.5299 19.1432 13.1749 19.4816C13.518 19.6616 13.5391 20.162 13.1808 20.3094C12.0288 20.783 10.7931 21.0245 9.54433 21.0199C8.29553 21.0153 7.05806 20.7648 5.90258 20.2827C4.74709 19.8006 3.69621 19.0963 2.80994 18.21C1.92367 17.3238 1.21938 16.2729 0.737256 15.1174C0.255136 13.9619 0.0046379 12.7244 6.3803e-05 11.4756C-0.00451058 10.2268 0.236929 8.99118 0.710597 7.83919Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
}
