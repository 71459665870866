import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';

import { api } from '../../services/api';
import { setAuthorizationHeader } from '../../services/interceptors';
import {
  createDeviceId,
  createTokenCookies,
  getAccessToken,
  getDeviceId,
  removeTokenCookies,
} from '../../utils/auth-cookies';
import { Language, Role } from '../../types/Dict';
import { Client } from '../../types/Client';
import { CoachInfo } from '../../app/pages/CoachPage/slice/types';

// Types
interface User {
  id: number;
  email: string;
  clientid: number;
  client: Client;
  role: Role;
  firstname: string;
  lastname: string;
  language: Language;
  lastloggedin: string | null;
  coach_profile_id: number;
  coach_profile: CoachInfo | null;
  coach_accepted_terms: boolean | null;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface OtpVerify {
  email: string;
  secret: string;
}

interface SignInResponse {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
  twoFactorAuth: boolean;
}

interface OtpResponse {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
}

interface BrowserLocation {
  pathname: string;
}

interface AuthContextData {
  signIn: (
    credentials: SignInCredentials,
    from: BrowserLocation | null,
  ) => Promise<void | AxiosError>;
  otpVerify: (
    params: OtpVerify,
    from: BrowserLocation | null,
  ) => Promise<void | AxiosError<{ message: string }>>;
  signOut: () => void;
  user: User;
  isAuthenticated: boolean;
  loadingUserData: boolean;
  getUserData: (loading: boolean) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

// Context creation
const AuthContext = createContext({} as AuthContextData);

// Provider component
export function AuthProvider({ children }: AuthProviderProps) {
  // State
  const [user, setUser] = useState<User | null>();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [deviceId, setDeviceId] = useState<string | null>(null);

  // Hooks
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { i18n } = useTranslation();

  // Derived state
  const isAuthenticated = Boolean(user);
  const userData = user as User;

  // Initialize tokens
  const { loading: accessTokenLoading } = useAsync(async () => {
    const token = await getAccessToken();
    const device = await getDeviceId();

    setAccessToken(token);
    setDeviceId(device);
  });

  // Initialize device ID
  useAsync(async () => {
    if (!deviceId) await createDeviceId();
  });

  // Handle token changes
  useEffect(() => {
    if (!accessToken && !accessTokenLoading) flashUserData(pathname);
  }, [pathname, accessToken, accessTokenLoading]);

  // Initialize user data if token exists
  useAsync(async () => {
    const token = await getAccessToken();

    if (token) {
      // @ts-ignore
      setAuthorizationHeader(api.defaults, token);
      await getUserData();
    }
  });

  // Authentication methods
  async function signIn(
    { email, password }: SignInCredentials,
    from: BrowserLocation | null = null,
  ) {
    try {
      const response = await api.post<SignInResponse>('/auth/signin', {
        email,
        password,
        device_id: deviceId,
      });
      const { accessToken, refreshToken, user, twoFactorAuth } = response.data;

      if (twoFactorAuth) {
        history.push({
          pathname: '/auth/otp/verify',
          state: {
            email,
            password,
            from,
          },
        });
      } else if (user && accessToken && refreshToken) {
        await handleSuccessfulAuth(accessToken, refreshToken, user, from);
      }
    } catch (error) {
      return error as AxiosError;
    }
  }

  async function otpVerify(
    { secret, email }: OtpVerify,
    from: BrowserLocation | null = null,
  ) {
    try {
      const response = await api.post<OtpResponse>(`/auth/2fa`, {
        email,
        secret,
        device_id: deviceId,
      });
      const { accessToken, refreshToken, user } = response.data;

      if (user && accessToken && refreshToken) {
        await handleSuccessfulAuth(accessToken, refreshToken, user, from);
      }
    } catch (error) {
      return error as AxiosError<{ message: string }>;
    }
  }

  function signOut() {
    api.get(`/auth/logout`);
    flashUserData();
  }

  // Helper methods
  async function handleSuccessfulAuth(
    token: string,
    refreshToken: string,
    userData: User,
    from: BrowserLocation | null = null,
  ) {
    setAccessToken(token);
    await createTokenCookies(token, refreshToken);
    setUser(userData);
    await i18n.changeLanguage(userData.language.code || 'en');
    // @ts-ignore
    setAuthorizationHeader(api.defaults, token);
    history.push(from || '/');
  }

  async function flashUserData(newPathname = '/auth/login') {
    if (accessTokenLoading) return;

    await removeTokenCookies();
    setUser(null);
    setLoadingUserData(false);

    if (newPathname !== pathname) {
      history.push({
        pathname,
        search,
      });
    }
  }

  async function getUserData(loading: boolean = true) {
    loading && setLoadingUserData(true);

    try {
      const { data: user } = await api.get<User>('/auth/iam');

      if (user) {
        setUser(user);
        await i18n.changeLanguage(user?.language?.code || 'en');
      }
    } catch (error) {
      await flashUserData();
    }

    setLoadingUserData(false);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: userData,
        loadingUserData,
        signIn,
        signOut,
        otpVerify,
        getUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook
export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

// Export context for testing purposes
export { AuthContext };
