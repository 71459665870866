import React, { memo } from 'react';

import { Col, Row } from '../Grid';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';
import { PlusDesktopNav } from './DesktopNav/Plus';
import { usePermissions } from '../../../hooks/usePermissions';
import { PlusMobileNav } from './MobileNav/Plus';

export const NavBar = memo(() => {
  const isCoach = usePermissions({ tags: ['coach'] });

  return (
    <Row>
      <Col xs={12} display={{ xs: 'none', lg: 'block' }}>
        {isCoach ? <PlusDesktopNav /> : <DesktopNav />}
      </Col>
      <Col xs={12} display={{ xs: 'block', lg: 'none' }}>
        {isCoach ? <PlusMobileNav /> : <MobileNav />}
      </Col>
    </Row>
  );
});
