import React from 'react';

import { SVGIcon } from './SVGIcon';

interface Props {
  width?: number;
  height?: number;
}

export function QuestionnaireIcon(props: Props) {
  return (
    <SVGIcon viewBox="0 0 24 24" fill="none" width={24} height={24} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1.5C1 1.22386 1.22386 1 1.5 1H19.5C19.7761 1 20 1.22386 20 1.5V19.5C20 19.7761 19.7761 20 19.5 20H1.5C1.22386 20 1 19.7761 1 19.5V1.5ZM1.5 0C0.671573 0 0 0.671573 0 1.5V19.5C0 20.3284 0.671573 21 1.5 21H19.5C20.3284 21 21 20.3284 21 19.5V1.5C21 0.671573 20.3284 0 19.5 0H1.5ZM22.5 3C22.2239 3 22 3.22386 22 3.5C22 3.77614 22.2239 4 22.5 4C22.6326 4 22.7598 4.05268 22.8536 4.14645C22.9473 4.24021 23 4.36739 23 4.5V22.5C23 22.6326 22.9473 22.7598 22.8536 22.8536C22.7598 22.9473 22.6326 23 22.5 23H4.5C4.36739 23 4.24021 22.9473 4.14645 22.8536C4.05268 22.7598 4 22.6326 4 22.5C4 22.2239 3.77614 22 3.5 22C3.22386 22 3 22.2239 3 22.5C3 22.8978 3.15803 23.2794 3.43934 23.5607C3.72064 23.842 4.10218 24 4.5 24H22.5C22.8978 24 23.2794 23.842 23.5607 23.5607C23.842 23.2794 24 22.8978 24 22.5V4.5C24 4.10218 23.842 3.72064 23.5607 3.43934C23.2794 3.15803 22.8978 3 22.5 3Z"
        fill="currentColor"
      />
      <path
        d="M7.54004 6.94263C7.54032 5.45685 8.73374 4.28624 10.21 4.11849C11.6863 3.95073 13.0534 4.9176 13.387 6.36543C13.7207 7.81325 12.9146 9.28092 11.5138 9.77618C10.9299 9.98264 10.5396 10.5349 10.5399 11.1543L10.54 11.9426"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.54 14.9434C9.98775 14.9434 9.54004 15.3911 9.54004 15.9434C9.54004 16.4956 9.98775 16.9434 10.54 16.9434C11.0923 16.9434 11.54 16.4956 11.54 15.9434C11.54 15.3911 11.0923 14.9434 10.54 14.9434Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
}
