import styled, { css } from 'styled-components/macro';

import { ifProp, palette, PaletteProps } from '../../../styles/tools';

interface Props extends PaletteProps {
  $color?: boolean;
  $background?: boolean;
}

export const Span = styled.span.attrs((props: Props) => {
  const attrs: any = {};
  if (props.$color) attrs.color = props.palette;
  if (props.$background) attrs.background = props.palette;
  return attrs;
})<Props>`
  ${ifProp(
    '$color',
    css`
      color: ${palette('color', 'default', 0)};
    `,
  )}
  ${ifProp(
    '$background',
    css`
      padding: 1px 0.5rem;
      border-radius: 4px;
      color: ${palette('background', 'default', 1)};
      background: ${palette('background', 'default', 0)};
    `,
  )}
`;
