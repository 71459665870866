import { Button } from '../../components/Button';
import { useTranslation } from 'react-i18next';

import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useBillingContext } from '../../../context/BillingContext';
import { PaletteProps } from '../../../styles/tools';

interface Props extends PaletteProps {}

export function GetSeasonPassButton(props: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const { palette, children } = props;
  const { createCheckoutSession, stripe, product, hasSubscription } =
    useBillingContext();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    await createCheckoutSession();
    setLoading(false);
  };

  const { price, symbol } = useMemo(() => {
    if (!product) return { price: '', symbol: '' };

    const amount = product.unit_amount / 100;
    const currency = product.currency.toUpperCase();

    const currencySymbols = {
      USD: '$',
      EUR: '€',
    };

    return {
      price: amount.toFixed(0),
      symbol: currencySymbols[currency] || currency,
    };
  }, [product?.id]);

  return (
    <Button onClick={onClick} $loading={loading} palette={palette || 'primary'}>
      {children || t('buttonAction.getSeasonPass', { price, symbol })}
    </Button>
  );
}
