import React from 'react';

import { SVGIcon, SvgIconProps } from './SVGIcon';

interface Props extends SvgIconProps {
  width?: number;
  height?: number;
}

export function CoachPlusLogo(props: Props) {
  return (
    <SVGIcon viewBox="0 0 24 24" fill="none" width={24} height={24} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.312 18.7678C13.6183 18.7678 10.624 15.7735 10.624 12.0798C10.624 8.38614 13.6183 5.39185 17.312 5.39185C21.0056 5.39185 23.9999 8.38614 23.9999 12.0798C23.9999 15.7735 21.0056 18.7678 17.312 18.7678ZM15.5602 16.1802C15.2623 16.0535 15.1867 15.684 15.3477 15.4032C15.712 14.7682 15.9707 14.0735 16.11 13.3542H13.4537C13.2072 13.3542 12.9864 13.1928 12.9386 12.9509C12.8077 12.2874 12.8286 11.5955 13.0003 10.9413C13.0599 10.7141 13.273 10.5676 13.5079 10.5676H16.0603C15.9138 9.93103 15.6747 9.3168 15.3536 8.74838C15.1952 8.4678 15.2728 8.10081 15.5696 7.97539C16.2237 7.69901 16.9414 7.58127 17.6503 7.63391C17.8299 7.64725 17.9861 7.75881 18.0645 7.921C18.4685 8.75671 18.7505 9.65092 18.8979 10.5676H21.1161C21.3511 10.5676 21.5641 10.7141 21.6221 10.9418C21.7858 11.5848 21.8075 12.2972 21.6836 12.9505C21.6376 13.1927 21.4169 13.3542 21.1703 13.3542H18.9329C18.8006 14.3312 18.494 15.3418 18.056 16.2414C17.9774 16.4028 17.8214 16.5133 17.6423 16.5263C16.9326 16.5777 16.2144 16.4584 15.5602 16.1802Z"
        fill="#FF9300"
      />
      <path
        d="M0.710597 8.33894C1.18426 7.18696 1.88088 6.14121 2.76068 5.26141C3.64048 4.38162 4.68623 3.685 5.83821 3.21133C6.9902 2.73766 8.22587 2.49622 9.47466 2.5008C10.7235 2.50537 11.9609 2.75587 13.1164 3.23799C13.5369 3.41345 13.5047 4.00097 13.1032 4.21635C12.5228 4.52767 11.9808 4.90136 11.4863 5.32848C11.3398 5.455 11.1409 5.50273 10.9523 5.45933C10.4646 5.34711 9.96556 5.28926 9.46446 5.28743C8.58162 5.28419 7.70963 5.45486 6.89793 5.78862C6.08627 6.12235 5.35016 6.61285 4.73114 7.23187C4.11212 7.85089 3.62162 8.587 3.28788 9.39866C2.95413 10.2104 2.78346 11.0824 2.78669 11.9652C2.78993 12.8481 2.96705 13.7245 3.30902 14.5441C3.651 15.3637 4.1509 16.1098 4.7804 16.7393C5.4099 17.3688 6.156 17.8687 6.97563 18.2107C7.79522 18.5527 8.67164 18.7298 9.55454 18.733C9.99794 18.7347 10.4386 18.6924 10.8703 18.6077C11.0564 18.5711 11.2498 18.6222 11.3916 18.7482C11.9314 19.2279 12.5299 19.6429 13.1749 19.9814C13.518 20.1614 13.5391 20.6618 13.1808 20.8091C12.0288 21.2828 10.7931 21.5242 9.54433 21.5197C8.29553 21.5151 7.05806 21.2646 5.90258 20.7825C4.74709 20.3004 3.69621 19.5961 2.80994 18.7098C1.92367 17.8235 1.21938 16.7726 0.737256 15.6172C0.255136 14.4617 0.0046379 13.2242 6.3803e-05 11.9754C-0.00451058 10.7266 0.236929 9.49093 0.710597 8.33894Z"
        fill="currentColor"
      />
    </SVGIcon>
  );
}
