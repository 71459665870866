import React from 'react';

import { SVGIcon } from './SVGIcon';

interface Props {
  width?: number;
  height?: number;
}

export function PencilIcon(props: Props) {
  return (
    <SVGIcon
      viewBox="0 0 16 17"
      fill="none"
      width={16}
      height={17}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4759 5.94077L10.5586 4.02344L3.75716 10.8249L5.6745 12.7422L12.4759 5.94077Z"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.75735 10.8252L2.79883 13.7012L5.67485 12.7427L3.75735 10.8252Z"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4756 5.94038L10.5586 4.02333L10.8781 3.70382C11.1338 3.45686 11.4763 3.3202 11.8317 3.32329C12.1872 3.32638 12.5273 3.46897 12.7786 3.72034C13.03 3.9717 13.1726 4.31175 13.1757 4.66722C13.1788 5.0227 13.0421 5.36517 12.7951 5.62087L12.4756 5.94038Z"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SVGIcon>
  );
}
