import styled, { css } from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import { TabsWrapper } from '../Tabs';
import {
  ifProp,
  palette,
  PaletteProps,
  prop,
  theme,
} from '../../../styles/tools';
import { SubArticleTitle } from '../SubArticle';
import { UserValidateProps } from '../../../utils/validate-user-permissions';
import { usePermissions } from '../../../hooks/usePermissions';
import { CardProvider } from './CardContext';

type Props = PaletteProps & {
  to?: string;
  onClick?: (event: React.MouseEvent) => void;
  padding?: number | [number, number];
  target?: string;
  style?: React.CSSProperties;
  className?: string;
} & UserValidateProps;

export const cardStyles = css<{ padding?: Props['padding'] }>`
  padding: ${({ padding }) => {
    if (!padding) return 'calc(1rem - 2px)';
    if (typeof padding === 'number') return `calc(${padding}rem - 2px)`;
    if (Array.isArray(padding))
      return `calc(${padding[0]}rem - 2px) calc(${padding[1]}rem - 2px)`;
  }};
  border: 2px solid ${palette('card', 'default', 0)};
  background: ${palette('card', 'default', 0)};
  border-radius: 0.5rem;
  box-shadow: ${palette('card', 'default', 3)};
  display: block;
  break-inside: avoid;
  flex: auto;

  ${TabsWrapper} {
    background: ${theme('palette.tabs.default.0')};
  }

  ${SubArticleTitle} {
    background: ${palette('card', 'default', 0)};
  }
`;

export const styledLinkCard = css`
  ${cardStyles}

  &:hover {
    border-color: ${palette('card', 'default', 1)};
    transition: border-color 100ms ease-out;
  }
`;

export const StyledRouterLinkCard = styled(RouterLink)<
  PaletteProps & { padding?: Props['padding'] }
>`
  ${styledLinkCard}
`;

export const StyledHrefCard = styled.a.attrs({
  rel: 'nofollow noreferrer',
})<PaletteProps & { padding?: Props['padding'] }>`
  ${styledLinkCard}
`;

export const StyledCard = styled.div<
  PaletteProps & { padding?: Props['padding'] }
>`
  ${cardStyles}

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;

      &:hover {
        border-color: ${palette('card', 'default', 1)};
        transition: border-color 100ms ease-out;
      }
    `,
  )}
`;

export function Card(props: PropsWithChildren<Props>) {
  const {
    to,
    padding,
    target,
    onClick,
    permissions,
    tags,
    children,
    style,
    className,
    palette,
  } = props;
  const hasAccess = usePermissions({ permissions, tags });

  const isExternalLink = to?.startsWith('http') || to?.startsWith('https');

  const handleExternalLink = useCallback(
    async (event: React.MouseEvent) => {
      onClick?.(event);

      if (Capacitor.isNativePlatform() && to) {
        event.preventDefault();
        await Browser.open({ url: to, presentationStyle: 'popover' });
      }
    },
    [onClick, to],
  );

  const renderComponent = () => {
    if (!to || !hasAccess)
      return (
        <StyledCard
          padding={padding}
          onClick={onClick}
          children={children}
          style={style}
          className={className}
          palette={palette}
        />
      );

    if (isExternalLink) {
      return (
        <StyledHrefCard
          href={to}
          target={Capacitor.isNativePlatform() ? '_self' : '_blank'}
          onClick={handleExternalLink}
          padding={padding}
          children={children}
          style={style}
          className={className}
          palette={palette}
        />
      );
    }

    return (
      <StyledRouterLinkCard
        to={to}
        target={target}
        padding={padding}
        onClick={onClick}
        children={children}
        style={style}
        className={className}
        palette={palette}
      />
    );
  };

  return (
    <CardProvider permissions={permissions} tags={tags}>
      {renderComponent()}
    </CardProvider>
  );
}
