import styled from 'styled-components';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components/macro';

import { palette, PaletteProps } from '../../../styles/tools';
import { UserValidateProps } from '../../../utils/validate-user-permissions';
import { usePermissions } from '../../../hooks/usePermissions';

interface Props extends PaletteProps {
  $underline?: boolean;
}

const styles = css`
  color: ${palette('link', 0, 'inherit')};

  &[href]:hover {
    color: ${palette('link', 'default', 1)};
  }

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;
    `,
  )}

  ${ifProp(
    '$underline',
    css`
      text-decoration: underline;
      text-decoration-color: ${palette('link', 'default', 3)};

      &:hover {
        text-decoration-color: ${palette('link', 'default', 3)};
      }
    `,
  )}
`;

export const Link = styled(RouterLink)<Props>`
  ${styles}
`;

export const A = styled.a<Props>`
  ${styles}
`;

export const LinkPermitted = ({
  permissions,
  tags,
  children,
  to,
  replace,
  component,
  ...props
}: LinkProps & UserValidateProps & Props) => {
  const hasAccess = usePermissions({ permissions, tags });

  if (!hasAccess) return <A {...props}>{children}</A>;

  return (
    <Link to={to} replace={replace} component={component} {...props}>
      {children}
    </Link>
  );
};
