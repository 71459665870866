import React from 'react';

import { SVGIcon } from './SVGIcon';

interface Props {
  width?: number;
  height?: number;
}

export function PersonOutlinedIcon(props: Props) {
  return (
    <SVGIcon viewBox="0 0 24 24" fill="none" width={24} height={24} {...props}>
      <path
        d="M3.00002 23.5V20.622C2.99499 19.187 3.87082 17.8959 5.20602 17.37L9.34201 15.724C9.4374 15.6865 9.5001 15.5945 9.50001 15.492V13.94C9.49976 13.8581 9.45941 13.7815 9.39201 13.735C8.53232 13.0833 8.02484 12.0687 8.01901 10.99C8.01323 10.9181 7.9768 10.8522 7.91901 10.809C7.54469 10.4808 7.34529 9.99658 7.38001 9.5C7.37599 9.17693 7.45264 8.85797 7.60301 8.572C7.65792 8.46933 7.72767 8.37532 7.81001 8.293C7.87397 8.22886 7.89877 8.13541 7.87501 8.048C7.61801 7.14 7.26201 5.656 7.97501 4.887C8.25483 4.5991 8.64271 4.44199 9.04401 4.454C9.12715 4.45278 9.2043 4.41046 9.25001 4.341C9.89001 3.362 11.458 3 12.75 3C14.256 3 16.139 3.492 16.485 4.876C16.6517 5.9452 16.5358 7.0394 16.149 8.05C16.1211 8.13784 16.1437 8.23391 16.208 8.3C16.495 8.63184 16.6427 9.06182 16.62 9.5C16.6547 9.99658 16.4553 10.4808 16.081 10.809C16.0232 10.8522 15.9868 10.9181 15.981 10.99C15.9752 12.0687 15.4677 13.0833 14.608 13.735C14.5402 13.7812 14.4997 13.858 14.5 13.94V15.491C14.5005 15.5933 14.563 15.6851 14.658 15.723L18.789 17.359C20.1268 17.8841 21.0049 19.1768 21 20.614V23.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </SVGIcon>
  );
}
