import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { rgba } from 'polished';

import { NavLink } from './NavLink';
import { Icon } from '../../Icon';
import { palette, theme, withProp } from '../../../../styles/tools';
import { CoachPlusLogo } from '../../IconV2';

export function PlusMobileNav() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Nav>
        <NavLink to="/plus/home" exact>
          <CoachPlusLogo inline />
          <br />
          {t('navigation.home')}
        </NavLink>
        <NavLink to="/plus/my-profile" exact>
          <Icon name="person" />
          <br />
          {t('navigation.myProfile')}
        </NavLink>
        <NavLink to="/settings" exact>
          <Icon name="settings" />
          <br />
          {t('navigation.settings')}
        </NavLink>
      </Nav>
    </Wrapper>
  );
}

const Wrapper = styled.header`
  padding: 6px 16px var(--safe-area-inset-bottom, 6px);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  border-top: 1px solid ${theme('global.background')};
  backdrop-filter: blur(25px);
  background-color: ${withProp(palette('card', 'default'), background =>
    rgba(background, 0.8),
  )};

  @media print {
    display: none;
  }
`;

const Nav = styled.div`
  display: flex;
  margin: 0 -8px;
  justify-content: space-around;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
