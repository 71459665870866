import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types/User';

import { profileActions as actions } from '.';
import { api } from '../../../../../services/api';
import { trainerTickerActions } from '../../../../features/TrainerTickerFeature/slice';

function* getUser() {
  const response = yield call(api.get, '/auth/iam');
  yield put(actions.changeUserData(response.data));
}

function* updateUser(action: PayloadAction<User>) {
  const response = yield call(api.patch, '/auth/iam', action.payload);
  yield put(actions.changeUserData(response.data));

  if (action.payload?.newsletter_countries) {
    yield put(trainerTickerActions.getTickers({}));
  }
}

export function* profileSaga() {
  yield takeLatest(actions.getUser.type, getUser);
  yield takeLatest(actions.updateUser.type, updateUser);
}
