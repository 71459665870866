import React from 'react';

import { SVGIcon } from './SVGIcon';

interface Props {
  width?: number;
  height?: number;
}

export function ClockIcon(props: Props) {
  return (
    <SVGIcon viewBox="0 0 20 21" fill="none" width={20} height={21} {...props}>
      <path
        d="M19.5 10.5C19.5 15.7467 15.2467 20 10 20C4.7533 20 0.5 15.7467 0.5 10.5C0.5 5.2533 4.7533 1 10 1C15.2467 1 19.5 5.2533 19.5 10.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11.3333V3.83334"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M13.6084 9.25001L10 11.3333"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </SVGIcon>
  );
}
